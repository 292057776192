.chartSVG {
  /* font-weight: bold; */
  top: -1rem;
  left: -0.5rem;
  position: relative;
  max-width: 400px;
  max-height: 280px;
  width: 100%;
  height: 100%;
  font-family: inherit;
  animation-name: fadeInSplit;
  animation-duration: 300ms;
  animation-delay: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  --initialDelay: 700ms;
  --animDurations: 300ms;
}

@keyframes grow {
  0% {
    transform: scaleY(-1);
  }
  0% {
    transform: scaleY(0);
  }
}

.animate1stBar {
  position: relative;
  transform: scaleY(-1);
  transform-origin: 50% 0;
  animation: 0.5s 3 forwards grow;
}

@keyframes growBarChart {
  0% {
    opacity: 1;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes fadeInSplit {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.day1main {
  opacity: 0;
  animation-name: growBarChart;
  animation-duration: var(--animDurations);
  animation-delay: var(--initialDelay);
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  transform-origin: 0 1051px;
}

.day1split {
  opacity: 0;
  animation-name: fadeInSplit;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 0.5);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.day1Amountcopy {
  opacity: 0;
  animation-name: fadeInSplit;
  animation-duration: var(--animDurations);
  animation-delay: var(--initialDelay);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.day2main {
  opacity: 0;
  animation-name: growBarChart;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 1.5);
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  transform-origin: 0 1051px;
}

.day2split {
  opacity: 0;
  animation-name: fadeInSplit;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 2.5);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.day2Amountcopy {
  opacity: 0;
  animation-name: fadeInSplit;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 2.5);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.day3split {
  opacity: 0;
  animation-name: fadeInSplit;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 3.5);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.day3Amountcopy {
  opacity: 0;
  animation-name: fadeInSplit;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 3.5);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.day4main {
  opacity: 0;
  animation-name: growBarChart;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 4);
  animation-timing-function: ease-out;
  transform-origin: 0 1051px;
  animation-fill-mode: forwards;
}

.day4split {
  opacity: 0;
  animation-name: fadeInSplit;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 5);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.day4Amountcopy {
  opacity: 0;
  animation-name: fadeInSplit;
  animation-duration: var(--animDurations);
  animation-delay: calc(var(--initialDelay) + var(--animDurations) * 5);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.st0 {
  font-weight: normal;
  color: rgb(var(--core-8));
}

.st1 {
  font-size: 64.2523px;
}
.st2 {
  letter-spacing: -1;
}
.st3 {
  color: rgb(var(--core-7));
  font-weight: 300;
}

.st4 {
  font-size: 49.6024px;
}
.st5 {
  fill: rgb(var(--primary));
}
.st6 {
  color: rgb(var(--core-8));
  font-weight: 400;
  text-align: left;
}
.st10 {
  fill: none;
  stroke: rgb(var(--primary));
  stroke-width: 5.02;
  stroke-miterlimit: 10;
}
